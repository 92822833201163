import React, { useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { CustomGrid } from 'src/components/custom-grid/custom-grid';
import Dropdown from 'src/components/dropdown/dropdown';

import usePlayerRankingFilters from '../utils/usePlayerRankingFilters';
import { RankListFilters } from './players-rankings';

interface PlayerRankingFiltersProps {
  filters: RankListFilters;
  setFilters: (filters: RankListFilters) => void;
  rankListCandidates: any;
  setSelectedList: (list: any) => void;
}

const PlayerRankingFilters: React.FC<PlayerRankingFiltersProps> = ({
  filters,
  setFilters,
  rankListCandidates,
  setSelectedList,
}) => {
  const { t } = useTranslation();

  const handleUpdateFilter = useCallback(
    (filterUpdate) => {
      setFilters({ ...filters, ...filterUpdate });
    },
    [filters, setFilters],
  );

  const playerRankingFilters = usePlayerRankingFilters(
    filters,
    handleUpdateFilter,
    t,
    rankListCandidates,
    setSelectedList,
  );
  const filterOptions = playerRankingFilters();

  return (
    <CustomGrid container spacing={{ margins: { sm: ['bottom', 'top'] } }}>
      {filters &&
        filterOptions.map((filter, index) => {
          const marginStyle = { [index !== 0 ? 'sm' : 'xxs']: 'left' };
          return (
            <Dropdown
              key={filter.id}
              options={filter.options}
              spacing={{ margins: { md: 'bottom', ...marginStyle } }}
              selected={filters[filter.id] ?? filter.options?.[0]}
              onSelect={filter.onSelect}
              {...filter.props}
            />
          );
        })}
    </CustomGrid>
  );
};

export default PlayerRankingFilters;
