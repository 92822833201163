import React from 'react';

import { useQuery } from '@apollo/client';
import cx from 'classnames';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { meshGatewayClient } from 'src/apollo/client';
import { PropertySortInput } from 'src/graphql-types/globalTournamentTypes';
import {
  DualMatchesPaginated,
  DualMatchesPaginated_dualMatchesPaginated_items,
} from 'src/graphql-types/ita/DualMatchesPaginated';
import { desk_HomeTeam } from 'src/graphql-types/ita/globalITATypes';
import { Spacing } from 'src/hooks/spacing';
import { initQueryFilterTableHook } from 'src/hooks/use-query-filter-table';
import { GET_DUAL_MATCHES_PAGINATED } from 'src/queries/ITA';

import { CustomGrid } from '../custom-grid/custom-grid';
import Panel from '../panel/panel';
import { GET_ITA_PERSON_BY_ID } from '../player-profile-ita/ita-player-profile-queries';
import * as styles from './dual-match.module.less';
import { formatResult, getOpponentTeam } from './dual-matches.utils';

interface DualMatchProps {
  id: string;
}

enum scoringFormatEnum {
  COLLEGE_DEFAULT = '7 Points',
  COLLEGE_JUCO = '9 Points',
  COLLEGE_D3 = '9 Points',
}

const DualMatchesPanel: React.FC<DualMatchProps> = ({ id }) => {
  const { data: teamData } = useQuery(GET_ITA_PERSON_BY_ID, {
    client: meshGatewayClient,
    variables: {
      uniqueId: id,
      uniqueIdType: 'personId',
    },
  });

  const playerData = teamData?.personById;
  const seasonData = teamData?.currentSeason;

  const currentTeam = playerData?.groups?.find((group) => group.seasonId === seasonData?.id);
  const teamId = currentTeam?.groupId;

  type TableItem = DualMatchesPaginated_dualMatchesPaginated_items;

  const useQFT = initQueryFilterTableHook<DualMatchesPaginated, TableItem>(GET_DUAL_MATCHES_PAGINATED);

  const currentYear = new Date().getFullYear().toString();

  const getQueryVarsFromContext = (ctx) => {
    const { season } = ctx.filters;
    const { limit, offset } = ctx.paging;

    return {
      teamId: teamId,
      limit,
      skip: offset,
      filter: {
        seasonStarting: season,
        teamIds: [teamId],
      },
      sort: ctx?.sorting?.length
        ? {
            field: ctx.sorting[0].property,
            direction: ctx.sorting[0].sortDirection,
          }
        : undefined,
    };
  };

  const filterSpacing: Spacing = {
    base: 6,
    margins: {
      xs: ['bottom', 'right'],
    },
  };

  const { t } = useTranslation();

  const startingYear = 2021;
  const seasonFilterOptions: { label: string; value: string }[] = [];

  for (let i = parseInt(currentYear); i >= startingYear; i--) {
    seasonFilterOptions.push({ label: `${i}/${i + 1}`, value: i.toString() });
  }

  const {
    components: { FilterBar, AdminTable, props },
  } = useQFT(
    {
      season: {
        position: 2,
        type: 'select',
        initialValue: currentYear,
        props: {
          options: seasonFilterOptions,
          classNames: {
            trigger: styles.genderTrigger,
          },
          placeholder: t('2023/2024'),
        },
        spacing: filterSpacing,
      },
    },
    {
      columns: [
        {
          key: 'START_DATE',
          title: t('date/time'),
          getValue: (playerData) =>
            t('dateFormat.MMM DD, YYYY (ddd) hh mm A', {
              date: moment(playerData?.startDateTime?.dateTimeString).local(),
            }) || '-',
          sort: true,
          widthClassName: cx(styles.tableCell, styles.stdName),
        },
        {
          key: 'opponent',
          title: t('opponent'),
          getValue: (d) => getOpponentTeam(d.teams, d.contextualInfo, teamId, t),
          sort: false,
          widthClassName: cx(styles.tableCell, styles.stdName),
        },
        {
          key: 'location',
          title: t('town/city'),
          getValue: (t) => t.location || '-',
          sort: false,
          widthClassName: cx(styles.tableCell, styles.stdName),
        },
        {
          key: 'scoringFormat',
          title: t('match format'),
          getValue: (t) => scoringFormatEnum[`${t.scoringFormat}`] || '-',
          sort: false,
          widthClassName: cx(styles.tableCell, styles.stdName),
        },
        {
          key: 'results',
          title: t('results'),
          getValue: (t) => formatResult(t.teams, t.contextualInfo, teamId) || '-',
          sort: false,
          widthClassName: cx(styles.tableCell, styles.stdName),
        },
        {
          key: 'boxScore',
          title: ' ',
          getValue: (data) => <a href={`/players/${id}/boxScore/${data?.id}`}>{t('box score')}</a>,
          widthClassName: cx(styles.tableCell, styles.stdName),
        },
      ],
    },
    {
      columnSelectorId: 'ita-players',
      urlParams: {},
      mapFiltersToQueryOptions: ({ filters, helper }) => {
        return {
          client: meshGatewayClient,
          getTotalItems: (data) => data?.dualMatchesPaginated?.totalItems,
          transformVariables: (vars: { limit?: number; offset?: number; sorts?: PropertySortInput[] }) => {
            const { limit, offset, sorts } = vars;
            return getQueryVarsFromContext({
              filters,
              paging: { limit, offset },
              sorting: sorts,
              helper,
            });
          },
          fetchPolicy: 'no-cache',
        };
      },
      mapDataToTable: (data) => data?.dualMatchesPaginated?.items,
    },
  );

  return (
    <Panel title={t('dualMatches')}>
      <CustomGrid container spacing={{ margins: { sm: 'top' } }}>
        <FilterBar {...props.filterBar} />
      </CustomGrid>
      <AdminTable {...props.adminTable} hideTopPaginationInfo={true} />
    </Panel>
  );
};

export default DualMatchesPanel;
