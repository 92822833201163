// extracted by mini-css-extract-plugin
export var actionContainer = "suspension-player-module--action-container--c4637";
export var addIcon = "suspension-player-module--add-icon--03f7b";
export var barColorGreen = "suspension-player-module--bar-color-green--4a66d";
export var barColorSuspended = "suspension-player-module--bar-color-suspended--2fa18";
export var barColorWarning = "suspension-player-module--bar-color-warning--f3b02";
export var bodyBase = "suspension-player-module--body-base--6c832 suspension-player-module--site-font--51731";
export var bodyLarge = "suspension-player-module--body-large--a1f9b suspension-player-module--body-base--6c832 suspension-player-module--site-font--51731";
export var bodyLargeBold = "suspension-player-module--body-large-bold--50b22 suspension-player-module--body-base--6c832 suspension-player-module--site-font--51731";
export var bodyRegular = "suspension-player-module--body-regular--044eb suspension-player-module--body-base--6c832 suspension-player-module--site-font--51731";
export var bodyRegularBold = "suspension-player-module--body-regular-bold--c52bf suspension-player-module--body-base--6c832 suspension-player-module--site-font--51731";
export var bodySmall = "suspension-player-module--body-small--1a919 suspension-player-module--body-base--6c832 suspension-player-module--site-font--51731";
export var bodySmallBold = "suspension-player-module--body-small-bold--34e2e suspension-player-module--body-base--6c832 suspension-player-module--site-font--51731";
export var borderTop = "suspension-player-module--border-top--58a05";
export var breakWordContainer = "suspension-player-module--break-word-container--f39df";
export var buttonContainer = "suspension-player-module--button-container--31c22";
export var buttonIconBase = "suspension-player-module--button-icon-base--2c952";
export var buttons = "suspension-player-module--buttons--9a63b";
export var clickLink = "suspension-player-module--click-link--dc675";
export var dropdownBase = "suspension-player-module--dropdown-base--3c307";
export var dropdownSelectBase = "suspension-player-module--dropdown-select-base--dca24 suspension-player-module--text-input--6c03d";
export var flexCol = "suspension-player-module--flex-col--a0790";
export var formErrorMessage = "suspension-player-module--form-error-message--e1292";
export var h3 = "suspension-player-module--h3--81363";
export var h4 = "suspension-player-module--h4--e1cbd";
export var hoverLink = "suspension-player-module--hover-link--d0a14";
export var hoverRow = "suspension-player-module--hover-row--c2145";
export var linearProgress = "suspension-player-module--linear-progress--fcfe0";
export var membershipContainer = "suspension-player-module--membership-container--9c9ce suspension-player-module--flex-col--a0790 suspension-player-module--primary-border--0ed15";
export var membershipHeader = "suspension-player-module--membership-header--76f75";
export var membershipHeading = "suspension-player-module--membership-heading--6b961";
export var membershipLabel = "suspension-player-module--membership-label--f0899";
export var moreFiltersBorderClass = "suspension-player-module--more-filters-border-class--2c56c";
export var pageBg = "suspension-player-module--page-bg--011ae";
export var pointer = "suspension-player-module--pointer--c4e44";
export var points = "suspension-player-module--points--e8521";
export var primaryBorder = "suspension-player-module--primary-border--0ed15";
export var shadowBoxLight = "suspension-player-module--shadow-box-light--6b1e6";
export var siteFont = "suspension-player-module--site-font--51731";
export var slideDownAndFade = "suspension-player-module--slideDownAndFade--b1cc6";
export var slideLeftAndFade = "suspension-player-module--slideLeftAndFade--35e6e";
export var slideRightAndFade = "suspension-player-module--slideRightAndFade--14537";
export var slideUpAndFade = "suspension-player-module--slideUpAndFade--d14e5";
export var spacing = "suspension-player-module--spacing--441c9";
export var statusDecoration = "suspension-player-module--status-decoration--d1061";
export var suspensionContainer = "suspension-player-module--suspension-container--19add";
export var textInput = "suspension-player-module--text-input--6c03d";
export var textInverted = "suspension-player-module--text-inverted--8c2c8";
export var textMediumDark = "suspension-player-module--text-medium-dark--e8ea0";
export var tooltipFont = "suspension-player-module--tooltipFont--d3ee7";
export var unstyledButton = "suspension-player-module--unstyled-button--61b42";