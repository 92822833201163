import React, { useEffect, useState } from 'react';

import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useOrgName } from 'src/apollo/local-state';
import { PlayTracker } from 'src/components/play-tracker/play-tracker';
import { PlayerRankings } from 'src/components/player-ranking/player-ranking';
import SuspensionPlayer from 'src/components/suspension-player/suspension-player';
import { getEnvConfig } from 'src/config/config';
import { usePlayerByExternalId } from 'src/hooks/data/suspensions';
import { AppClient } from 'src/types';
import { StaffScope, useHasScope, useOrgHierarchy, userIsGlobalAdmin } from 'src/utils/auth';
import { isClientOneOf } from 'src/utils/helper/clients';
import { useV2Rankings } from 'src/utils/useV2Rankings';
import { StringParam, useQueryParams } from 'use-query-params';

import { Spinner, TabPanel, Tabs } from '@clubspark-react/clubspark-react-tools';

import DualMatchesPanel from '../dual-matches/dual-matches';
import EmptyState from '../empty-state/empty-state';
import { PlayerHistory } from '../player-history/player-history';
import PlayersRankings from '../rankings/players-rankings/players-rankings';
import * as styles from './player-tabs.module.less';

const isIta = isClientOneOf(AppClient.ITA);

export const PlayerTabs = ({ id }) => {
  const suspensionsAccess = useHasScope([StaffScope.SUSPENSIONS]);
  const orgHierarchy = useOrgHierarchy();
  const isNationalStaff = orgHierarchy?.length === 1;
  const isGlobalAdmin = userIsGlobalAdmin();
  const orgName = useOrgName();

  const { data, loading } = usePlayerByExternalId(id);
  const sectionName = data?.personByExternalId?.section?.name;
  const districtName = data?.personByExternalId?.district?.name;
  const tabValue = getTabValue();
  const [query, setQuery] = useQueryParams({
    tab: StringParam,
  });
  const { t } = useTranslation();
  const initialValue = query?.tab ? tabValue[query.tab] ?? 0 : 0;
  const [value, setValue] = useState<number>(initialValue);

  useEffect(() => {
    initialValue && setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    if (value !== undefined && value !== null) {
      const tabEntries = Object.entries(getTabValue());
      const hasValue = tabEntries.some((entry) => entry.includes(value));
      const firstEntry = tabEntries?.[0];
      if (hasValue) {
        setQuery({ tab: getTabQuery(value) });
      } else if (firstEntry) {
        setQuery({ tab: firstEntry?.[0] });
      }
    }
  }, [value]);

  const handleChange = (_: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  function isTrue(value: string) {
    return value === 'true';
  }

  function getPlayTrackerVisibility() {
    const personAge = data?.personByExternalId?.age;
    if (isIta || !personAge) return false;
    return personAge < 19;
  }

  function getRankingsVisiblity() {
    if (isIta) return false;
    return isTrue(getEnvConfig().RANKINGS_REQUIRES_GLOBAL_ADMIN) ? isGlobalAdmin : true;
  }

  function getPlayerTournamentsVisibility() {
    return isIta;
  }

  function getDualMatchesVisibility() {
    return isIta;
  }

  function getSuspensionVisibility() {
    if (isIta) return false;
    const matchesDistrictOrSectionOrIsNationalStaff =
      orgName === districtName || orgName === sectionName || isNationalStaff;
    if (!suspensionsAccess || !matchesDistrictOrSectionOrIsNationalStaff) {
      return false;
    }
    return isTrue(getEnvConfig().SUSPENSIONS_REQUIRES_GLOBAL_ADMIN) ? isGlobalAdmin : true;
  }

  function getTabValue() {
    const tabVisibility = {
      playTracker: getPlayTrackerVisibility(),
      rankings: getRankingsVisiblity(),
      suspensions: getSuspensionVisibility(),
      tournaments: getPlayerTournamentsVisibility(),
      dualMatches: getDualMatchesVisibility(),
    };

    const visibleTabs = Object.entries(tabVisibility)
      .filter(([_, value]) => Boolean(value))
      .map(([key, _], index) => [key, index]);
    return Object.fromEntries(visibleTabs);
  }

  function getTabQuery(value: number) {
    const tabValue = getTabValue();
    switch (value) {
      case tabValue['playTracker']:
        return 'playTracker';
      case tabValue['rankings']:
        return 'rankings';
      case tabValue['suspensions']:
        return 'suspensions';
      case tabValue['tournaments']:
        return 'tournaments';
      case tabValue['dualMatches']:
        return 'dualMatches';
      default:
        return '';
    }
  }

  const getTabIndex = (tab: 'playTracker' | 'rankings' | 'suspensions' | 'tournaments' | 'dualMatches') => {
    return getTabValue()[tab];
  };

  const labelInfo = [
    { label: t('playtracker'), visible: getPlayTrackerVisibility() },
    { label: t('rankings'), visible: getRankingsVisiblity() },
    { label: t('suspensions'), visible: getSuspensionVisibility() },
    { label: t('tournaments'), visible: getPlayerTournamentsVisibility() },
    { label: t('dualMatches'), visible: getDualMatchesVisibility() },
  ];
  const labels = labelInfo.filter(({ visible = true }) => visible).map((i) => i.label);

  if (labels.length === 0) {
    return <EmptyState title={'No Play Tracker, Suspensions or Rankings data for this player'} icon="lg-empty-state" />;
  }

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <Tabs
        value={value}
        labels={labels}
        handleChange={handleChange}
        tabsWrapperStyle={styles.tabsWrapper}
        tabStyle={styles.tabStyle}
        indicatorStyle={styles.indicator}
        selectedStyle={styles.selected}
      />
      {getPlayTrackerVisibility() && (
        <Grid item xs={12}>
          <TabPanel value={value} index={getTabIndex('playTracker')} className={styles.tabPanel}>
            <PlayTracker id={id} />
          </TabPanel>
        </Grid>
      )}
      {getRankingsVisiblity() && (
        <Grid item xs={12}>
          <TabPanel value={value} index={getTabIndex('rankings')} className={styles.tabPanel}>
            {useV2Rankings() ? <PlayersRankings id={id} /> : <PlayerRankings id={id} />}
          </TabPanel>
        </Grid>
      )}
      {getPlayerTournamentsVisibility() && (
        <Grid item xs={12}>
          <TabPanel value={value} index={getTabIndex('tournaments')} className={styles.tabPanel}>
            <PlayerHistory id={id} />
          </TabPanel>
        </Grid>
      )}
      {getDualMatchesVisibility() && (
        <Grid item xs={12}>
          <TabPanel value={value} index={getTabIndex('dualMatches')} className={styles.tabPanel}>
            <DualMatchesPanel id={id} />
          </TabPanel>
        </Grid>
      )}
      {getSuspensionVisibility() && (
        <Grid item xs={12}>
          <TabPanel value={value} index={getTabIndex('suspensions')} className={styles.tabPanel}>
            <SuspensionPlayer id={id} />
          </TabPanel>
        </Grid>
      )}
    </>
  );
};
