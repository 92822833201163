import React from 'react';

import { useQuery } from '@apollo/client';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { meshGatewayClient } from 'src/apollo/client';
import { PlayerCategoryEnum, SexEnum } from 'src/graphql-types/ita/globalITATypes';
import { usePersonGender } from 'src/utils/helper/players';

import { BodyLargeBold } from '@clubspark-react/clubspark-react-tools';

import Breadcrumbs from '../breadcrumbs/breadcrumbs';
import ItaPlayerProfileHeader from '../player-profile-header-ita/ita-player-profile-header';
import { PlayerTabs } from '../player-tabs/player-tabs';
import Spinner from '../spinner/spinner';
import SuccessNotification from '../success-notification/success-notification';
import { PageMaxWidth } from '../util-components/util-components';
import { GET_ITA_PERSON_BY_ID } from './ita-player-profile-queries';

interface Props {
  id?: string;
}

const ItaPlayerProfile: React.FC<Props> = ({ id }) => {
  const { t } = useTranslation();
  const { getTranslation: getGenderTranslation } = usePersonGender();

  const { data, error, loading } = useQuery(GET_ITA_PERSON_BY_ID, {
    client: meshGatewayClient,
    variables: {
      uniqueId: id,
      uniqueIdType: 'personId',
    },
  });

  const playerData = data?.personById;
  const seasonData = data?.currentSeason;

  const fullName = `${playerData?.standardGivenName} ${playerData?.standardFamilyName}`;
  const currentTeam = playerData?.groups?.find((group) => group.seasonId === seasonData?.id);
  const isJunior = playerData?.category === PlayerCategoryEnum.Junior;
  const tennisNumber = playerData?.worldTennisNumbers?.find((i) => i.type === 'SINGLE')?.tennisNumber;
  const confidence = playerData?.worldTennisNumbers?.find((i) => i.type === 'SINGLE')?.confidence;

  const topRowDetails = [
    {
      label: t('ita id'),
      value: playerData?.externalId || t('n/a'),
    },
    {
      label: t('tennis id'),
      value: playerData?.tennisId || t('n/a'),
    },
    {
      label: undefined,
      value:
        (getGenderTranslation(playerData?.sex as SexEnum) || t('n/a')) +
        ', ' +
        (playerData?.age || t('n/a')) +
        t('years abrv'),
    },
  ];

  const bottomRowDetails = [
    {
      label: t('date of birth'),
      value:
        t('dateFormat.MM/DD/YY', {
          date: moment(playerData?.birthDate).local(),
        }) || t('n/a'),
    },
    {
      label: t('class'),
      value: playerData?.class || t('n/a'),
    },
    {
      label: t('nationality'),
      value: playerData?.nationalityCode || t('n/a'),
    },
    {
      label: t('team'),
      value: currentTeam ? currentTeam.groupName : t('n/a'),
    },
    {
      label: t('wtn singles'),
      value: tennisNumber || undefined,
      isWtn: true,
    },
  ];

  if (error) return <BodyLargeBold>{t('generic error')}</BodyLargeBold>;

  if (loading || !data) {
    return <Spinner />;
  }

  if (!data?.personById) {
    return <BodyLargeBold>{t('no player profile found')}</BodyLargeBold>;
  }

  return (
    <PageMaxWidth>
      <Breadcrumbs
        paths={[
          { name: t('players'), to: '/players' },
          { name: fullName, active: true },
        ]}
      />
      <SuccessNotification atUrl={`/players/${id}`} variant={'success'} hideAfterMs={3000} />
      <ItaPlayerProfileHeader
        tennisID={playerData?.tennisId}
        loading={loading}
        topRowDetails={topRowDetails}
        bottomRowDetails={bottomRowDetails}
        isJunior={isJunior}
        tennisNumber={tennisNumber}
        confidence={confidence}
        fullName={fullName}
      />
      <PlayerTabs id={id} />
    </PageMaxWidth>
  );
};

export default ItaPlayerProfile;
