import React from 'react';

import { RouteComponentProps, Router } from '@reach/router';
import Layout from 'src/components/layout/layout';
import ItaPlayerProfile from 'src/components/player-profile-ita/ita-player-profile';
import SEO from 'src/components/seo';
import { usePrivateRoute } from 'src/utils/auth';

const PlayerProfilePage = () => {
  usePrivateRoute();
  return (
    <Router basepath="/players">
      <PlayerProfileRoute path="/:id" />
    </Router>
  );
};

interface PlayerProfileRouteProps extends RouteComponentProps {
  id?: string;
}

const PlayerProfileRoute: React.FC<PlayerProfileRouteProps> = ({ id }) => {
  return (
    <Layout>
      <SEO title="Player Profile" />
      <ItaPlayerProfile id={id} />
    </Layout>
  );
};

export default PlayerProfilePage;
